/* eslint-disable quotes */
import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
// @material-ui/icons

// core components
import { GridContainer, GridItem } from 'components/Grid/index';

import { aboutStyle } from './Sections.css';

const renderItem = (item, index) => {
	if (item.subItems) {
		return (
			<>
				<li key={index}>{item.item}</li>
				<ul className='sub-items'>{item.subItems.map(renderItem)}</ul>
			</>
		);
	}
	return <li key={index}>{item}</li>;
};

const renderGridItem = (item, index, size, classes) => (
	<GridItem
		className={classes.centered}
		key={index}
		xs={12}
		sm={12}
		md={size % 2 !== 0 && index + 1 === size ? 12 : 6}>
		{item.title && <h3 className={classes.header}>{item.title}</h3>}
		<ul className={classes.single}>{item.items.map(renderItem)}</ul>
	</GridItem>
);

const ServicesSection = ({ classes, items }) => (
	<div className={classes.section}>
		<GridContainer>
			{items.map((x, i) => renderGridItem(x, i, items.length, classes))}
		</GridContainer>
	</div>
);

ServicesSection.propTypes = {
	classes: PropTypes.object.isRequired,
	items: PropTypes.array.isRequired,
};

export default withStyles(aboutStyle)(ServicesSection);
